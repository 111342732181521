import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
// import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const Contact = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Contact - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">Nous Contacter</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGame"><br/>
            <br/>
            <br/>
            Mail: <a target="#" href="mailto:hello@plaiz.io" className="textChangeGame"><b>hello@plaiz.io</b></a> <br/>
            <br/>
            Téléphone: <a target="#" href="tel:0669773161" className="textChangeGame"><b>06 69 77 31 61</b></a> <br/>
            <br/>
            <br/>
            Réseaux Sociaux<br/>
            <br/>
            Instagram: <a target="#" href="https://instagram.com/plaiz_gang" className="textChangeGame"><b>@plaiz_gang</b></a> <br/>
            <br/>
            Facebook: <a target="#" href="https://fb.com/superplaiz" className="textChangeGame"><b>Plaiz</b></a> <br/>
            <br/>
            Youtube: <a target="#" href="https://www.youtube.com/channel/UCWexBI920O8mbza0u_k0S5g" className="textChangeGame"><b>Plaiz App</b></a> <br/>
        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default Contact

const styles = {
  backToHomeLink: {
    color: "white",
  },
}